<template>
   <div class="container_total">
    <div class="content">
        <div class="title">申报结果确认</div>
      <div class="enterprise flex_between">
             <div class="flex_start">
            <div class="titleName">企业名称:</div>
             <el-input class="input_company" v-model="dataMap.CompanyName" disabled placeholder=""  />

           </div>
           <el-button  type="primary" :loading="saveFlag?true:false" @click="btnSave">确认提交</el-button>
      </div>
    </div>
   
    <el-table
    :data="summaryData"
    border
    style="width: 100%"
    class="table_list"
    :header-cell-style="{background:'#DAE9FE'}"
    >
    <el-table-column
      prop="key"
      label="响应时间"
     align="center">
    </el-table-column>
    <el-table-column
      prop="count"
      label="计划削减容量(KW)"
     align="center">
    </el-table-column>
    <el-table-column
      prop="proportion"
      label="削减比例"
      align="center"
      >
      <template slot-scope="scope">
          <span  :class="scope.row.proportion>80?'color_red':scope.row.proportion<50?'color_green':''">{{scope.row.proportion+'%'}}</span>
      </template>
   
    </el-table-column>
  </el-table>
   </div>
</template>

<script>

export default {
  name:'demandTotal',
  props: {
          totalData:Object 
     },
data(){
   return{
       dataList:[],
       summaryData:[],
       companyName:'6988',
       saveFlag:false,
       dataMap:{}
  
   }
     },

     created (){
      
   this.dataList=JSON.parse(JSON.stringify(this.totalData.array))
   this.dataMap=this.totalData.dataMap

     if(this.dataList.length>0){
        let sumData=[],total=[]
        total=this.dataList[0].timeAll
        total.forEach(tol => {
        sumData.push({key:tol.key,count:0,value:0})
        });

  this.dataList.forEach(val => {
   
   
    val.timeAll.forEach((com,index)=>{
      sumData[index].count+=com.count?Number(com.count):0
      sumData[index].value+=com.value?Number(com.value):0

    })
 

  });

       sumData.forEach(sum => {
       sum.count=parseFloat((sum.count?sum.count:0).toFixed(1))
      sum.proportion=(Math.round(((sum.count/sum.value)*100)?Number((sum.count/sum.value)*100):0))

    });
     
     console.log(sumData)
  
    this.summaryData=sumData


  }
      },
      mounted(){
     
      
      },
      methods:{

      btnSave(){
           this.saveFlag=true
         
      let that=this,datas=[]
       console.log(that.dataList)
    that.dataList.forEach(val => {
      let currentData={
        id:val.Id,
        huHao:val.HuHao,
        responseComandId:val.ResponseComandId,
        companyLoadInfoId:val.CompanyLoadInfoId,
        customerId:val.CustomerId,
        isReponse:val.IsReponse,
        remark:val.remark
      } 
      
      let array=[]
      array=val.timeAll
        array.forEach(ele => {
          let indexs=ele.key.indexOf(":")
        
          currentData['timeCol'+parseFloat(ele.key.substring(0,indexs))]=ele.count?ele.count:0
       
        });
   datas.push(currentData)

  
    });

    that.$axiosPost('/api/ResponseDemand/SaveResonseDetail',datas).then(msg=>{

           if(msg.Tag==1){
      
            this.$message.success(msg.Message);
            that.$emit('success')
            
        
           }else{
             this.$message.error(msg.Message);

           }
          that.saveFlag=false
    })


    },

      },



}
</script>

<style lang="scss" scoped>

.container_total{

width: 100%;
 background: #fff;

}
.content{
     padding: 30px;
.title{
    font-size: 18px;
    width: 100%;
    height: 30px;
   position: relative;
  padding-left: 20px;
  padding-bottom: 16px;
font-family: PingFang SC-Bold, PingFang SC;
font-weight: bold;
color: #2E353E;
border-bottom: 1px solid #DDE2E6;
display: flex;
align-items: center;
position: relative;
   &:before {
    content: "";
    width: 6px;
    height: 20px;
    background-color: #3e96fc;
    border-radius: 2px;
    display: block;
    position: absolute;
    left: 5px;
    top: 6px;
  }
}

}
.table_list{
    font-size: 18px;
    font-weight: bold;
    color: #2E353E;
}
.enterprise{
    width: 100%;
    margin: 24px 0;
       .input_company{
   margin-right:40px;
   width:450px;
   }
}
.titleName{
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #808992;
        padding: 16px 0;
        margin-right: 10px;
}

</style>