<template>
   <div class="container_explain">
     <div class="content">
        <div class="content_title">填报说明</div>
         <div class="content_txt">
               <!-- <div>1、基线负荷数据为贵司历史同类型日（剔除错峰和响应）的负荷均值；</div>
               <div>2、响应容量为可下调减少的单位小时用电量，即基线负荷减去实际用电负荷；</div>
               <div>3、考核基线是根据申报容量计得实际可用最大功率，超过该值即为无效响应且被考核；</div>
               <div class="color_red">4、如贵司全部时段的中标容量均超过基线负荷的60%，可不被纳入有序用电（即无须错峰停电）；</div>
               <div class="color_red">5、请根据贵司实际生产调节情况进行填报，红包金额与申报比例挂钩，关注公众号（邀约结束）可领取红包。</div> -->
               <div>1、历史基线负荷为贵司历史同类型日（剔除强制错峰和主动响应）的负荷均值；</div>
               <div>2、预计可用容量为贵司在执行日预留的用电负荷，请根据实际生产经营情况确定；</div>
               <div>3、计划削减容量为减少的每小时用电量，即历史基线负荷减去预计可用容量；</div>
               <div class="color_red">4、请关注公众号，在邀约结束后可领取红包，红包金额与申报比例直接挂钩。</div>
         </div>
         <div class="content_btn_box flex_center">
         <el-button class="content_btn" size="medium" v-if="count==0" @click="closeWin()">已知晓</el-button>
         <el-button  type="info" class="content_btn content_btn_after" v-else disabled   >已知晓（{{count}}）</el-button>


         </div>
     </div>
   </div>
</template>

<script>

export default {
  name:'explain',
data(){
   return{
     count:3,
     timer:null
 
   }
     },

     created (){
         let that=this
      this.timer = setInterval(()=>{
        this.num()
         if(that.count==0){
              clearInterval(this.timer);
             
         }
      },1000)
      },
      mounted(){
       
             
      },
      methods:{
       num(){
           this.count--;
       },
       closeWin(){
            
           this.$emit('cancel',true)
       }
      },
 beforeDestroy() {
    clearInterval(this.timer);
  },

}
</script>

<style lang="scss" scoped>

.container_explain{
padding: 0;
width: 100%;
//  background: #fff



}
.content{
     padding: 30px 0;
     font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #2E353E;
      background:url('../../../../assets/img/shading.png') no-repeat;
     background-size:100% 100%;
     .content_txt{
     padding: 0 30px;
  
     display: flex;
     flex-direction:column;
      div{
         width: 100%;
         margin: 10px 0;
         line-height: 250%;
        //  white-space: nowrap;
         font-size: 17px;
      
     }
     }
     .content_title{
         font-size: 21px;
         text-align: center;
         padding: 25px 30px;
      margin-bottom: 35px;
     }
     .content_btn_box{
         width: 100%;
        margin: 30px 0;
        
        margin-top: 65px;
   .content_btn{
    width: 118px;
    height: 40px;
    font-size: 18px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #2680EB;
    border: 1px solid #2680EB;
    padding: 10px 20px;
  
       display: flex;
    justify-content: center;
    align-items: center;
     }

     }

    .content_btn_after{
    color: #E24D4D !important;
    border: none !important;
     }
}

</style>