<template>
<div>
       
   <div class="container">

        <div class="content">
     
            <div class="title">     <div class="flex_start"> <span>需求响应</span> <div class="flex_start news_box"><img src="../../../assets/new.png"/>
            <!-- <span class="news" :style="'color: '+newColor">如贵司全部时段的中标容量均超过基线负荷的60%，可不被纳入有序用电（即无须错峰停电）</span> -->
            <span class="news" :style="'color: '+newColor">请根据实际生产经营情况确定执行日预留的用电负荷，由历史基线负荷减去预计可用容量得出计划削减容量！！！</span>
            </div>
              </div></div>
     
        <div class="action flex_between">
            <div class="flex_start">
            <div class="titleName">企业名称:</div>
            <el-select  @change="changeToken"   class="input_company" v-model="CompanyId" placeholder="请选择">
                        <el-option
                      v-for="item in companyList"
                        :key="item.Id"
                        :label="item.CompanyName"
                        :value="item.Id"
                    >
                    <div class="flex_between"><span>{{item.CompanyName}}</span><span :class="item.IsResponse=='-1'?'color_red':'color_green'">{{item.IsResponse=='-1'?'未申报':'已申报'}}</span></div>
                        </el-option>
               </el-select>
       
           </div>
           <div v-if="CompanyId">
            <div class="flex_start"     v-if="!historyCheck" >
              <span class="titleName">历史申报响应数据:</span>
                 <el-date-picker
                   popper-class="month_picker"
                v-model="selectedDate"
                 type="month"
                placeholder="选择日期"
                @change="dateChange"
               value-format=yyyy-MM-dd
               :clearable="false"
            
        
                >
                </el-date-picker>
     
        
            </div>
         <el-button v-else type="primary" @click="resetCheck">返回</el-button>
         </div>
        </div>
         
      
      <div class=" description flex_between" v-if="CompanyId">
          <div class="flex_start">
       <span >选中申报日期：<span class="date">{{listData.length>0?dataMap.RunDate:'/'}}</span></span>
       <div class="flex_start">
    <span>统一削减比例：</span>
        <el-select class="response_All" :style="listData.length>0?'width:20%':'width:30%'"   :disabled="!choiceData.isHandle" @change="choosePicker(0)" v-model="proportion" placeholder="请选择">
        <el-option  
           v-for="item in dimension"
              :key="item.value"
              :label="item.label"
              :value="item.value">


           </el-option>
         </el-select>


         <div class="flex_start" v-if="listData.length>0">
                 <span class="circular" :class="state=='已申报'||state=='已中标'?'bg_green':state=='未申报'||state=='未中标'?'bg_red':'bg_gray'" ></span>
                 <span >{{state}}</span>
         </div>


         </div>
  
             </div>

       <template v-if="choiceData.isHandle">
     <el-button  type="primary" :loading="saveFlag?true:false" @click="saveTotal()">提交</el-button>

     <!-- btnSave  -->
     </template>

      <template v-if="HasDoneResult==1">
     <el-button  type="primary"  @click="exportResult()">导出详情</el-button>
     </template>

     </div>
     <div class="response_box" >
     <div class="invite">
         <div class="invite_item_title" >
        <div class="invite_title">邀约信息</div>
         </div>
         <div class="table_line"></div>
         <template  v-for="(item,indexSwitch) in listData">
         <div v-if="listData.length>0"  :key="item.ResponseComandId">
        <div class="invite_item" :class="inviteIndex==indexSwitch?'invite_item_choose':''" @click="switchInvite(item,indexSwitch)">
              <div class="flex_between" style="margin-bottom: 24px;"><span class="item_label">截止申报日期：</span><span class="item_date">{{item.EndDeclareTime}}</span></div>
            <div class="flex_between" style="margin-bottom: 24px;">
          <span class="item_label description color_blue">{{item.RunDate}} </span>
          <span class="item_date" :class="item.ResponseType=='已取消'?'color_lightgray':item.ResponseTypeVal==4?'color_green':item.ResponseTypeVal==1?'color_green':item.ResponseTypeVal==6?'color_lightgray':'color_red'">
            {{item.ResponseType}}
            <!-- {{item.ResponseType=='已取消'||item.ResponseType=='已中标'?item.ResponseType:(item.invalid?item.ResponseType:'已过期')}} -->
            </span></div>
         </div>
         <div class="table_line" v-if="(listData.length-1)!=indexSwitch"></div>
      
      </div>
      </template>
      <div  class="emty" v-if="listData.length<=0" >
            <el-empty :image-size="160"  description="暂无数据"></el-empty>
            </div>

     </div>
     
     <div class="response_details"  v-loading="detailLoading">
            <template > 
          <div class="response_details_all" >
         <div class="response_details_item_title">
         <el-row class="invite_title flex_between">
             <el-col :span="4" class="invite_title_label">削减比例</el-col>
             <el-col :span="4" class="invite_title_label">用户号</el-col>
             <el-col :span="4" class="invite_title_label">响应时间</el-col>
             <el-col :span="4" class="invite_title_label" >{{choiceData.historyCheck?'历史基线负荷（KW）':'历史基线负荷（KW）'}}</el-col>

             
             <template v-if="HasDoneResult==1">
             <el-col :span="4" class="invite_title_label" >有效响应容量（KW）</el-col>
             <el-col :span="4" class="invite_title_label" >考核容量（KW）</el-col>
             </template>

             <template v-else>
             <el-col :span="4" class="invite_title_label" v-if="!choiceData.historyCheck">计划削减容量（KW）</el-col>
             <el-col :span="4" class="invite_title_label" v-if="!choiceData.historyCheck">预计可用容量（KW）</el-col>

             <el-col :span="4" class="invite_title_label" v-if="choiceData.historyCheck">应削减容量（kw）</el-col>
             <el-col :span="4" class="invite_title_label" v-if="choiceData.historyCheck">可用最大容量（kw）</el-col>
             </template>







         </el-row>
         </div>
             <div class="table_line"></div>


   
           <div class="response_details_container" v-if="array.length>0">
    <div v-for="(item,index) in array" :key="(index*7+7)" >
        <div class="response_details_item"    >
         <el-row class="invite_title flex_between">
             <el-col :span="4">
               <div class="flex_center">
            <el-select class="input_prop" style="width:65%" size="mini" :disabled="!choiceData.isHandle" @change="choosePicker(1,index)" v-model="item.proportion" placeholder="请选择">
          <el-option  
                v-for="item in dimension"
                    :key="item.value"
                    :label="item.label"
                  :value="item.value"
                > 
                </el-option>
        </el-select>
               </div>
        
        </el-col>


      
             <el-col :span="4">
                  <div class="flex_center">
                   <span> {{item.HuHao}}</span>
                    </div>
               </el-col>
             <el-col :span="4">
                 <div class="response_details_item_time">


                 <div>
                 <div v-for="(all,indexKey) in item.timeAll" :key="indexKey*2+2">
                <div  class="item_labels"  >{{all.key}}</div>
                </div>
               </div>

               
                 </div>
             </el-col>
             <el-col :span="4">
                <div class="response_details_item_time">


                 <div  >
                    <div v-for="(all,indexValue) in item.timeAll" :key="indexValue*4+4">
                <div  class="item_labels"  >{{all.value}}</div>
                </div>
               </div>

               
                 </div>
             </el-col>
             <el-col :span="4">
          <div  class="response_details_item_time">
          
         

         

              <div  >
             
                 <div v-for="(all,indexCount) in item.timeAll" :key="indexCount*5+5">
                <div class="flex_start item_input"  > 
                   <div class="flex_center"><el-input class="num_input" type="number" style="width:75%" placeholder="请填写" @input="inputKey(index,all.key)"  :disabled="!choiceData.isHandle"  size="mini" v-model="all.count" >	</el-input></div>
                           </div>
             
                </div>
               </div>

 

             </div>
             </el-col>
                  <el-col :span="4">
                <div class="response_details_item_time">

          
                          
                  <div  >
                    <div v-for="(all,indexLast) in item.timeAll" :key="indexLast*6+6">
                       <div  class="item_labels" style="text-align:center"   >{{all.max}}</div>
               
                </div>
               </div>

               
                 </div>



                


                  </el-col>
         </el-row>
        

         </div>
           <div  class="line" :key="index" v-if="index!=array.length-1"></div>
     </div>
          
 
        </div>
             <el-empty v-else :image-size="260" description="暂无数据"></el-empty>
              </div>
            </template>

     </div>


      </div>


        </div>
   

   </div>

         <el-dialog
  :visible.sync="dialogVisible"
  width="60%"
  class="template_dialog"
  >
    <history v-if="dialogVisible"  @check="check" ></history>

      </el-dialog>

  <el-dialog
  :visible.sync="dialoginfor"
  width="60%"
  :close-on-click-modal ="false"
  :show-close="false"
  class="template_dialog"
  >
    <information v-if="dialoginfor" :type="1" @success="updataCompany()"></information>
    </el-dialog>
    
    <el-dialog
    :visible.sync="dialogTotal"
    width="60%"
    class="template_dialog"
    >

   <demandTotal v-if="dialogTotal" :totalData="{array:array,dataMap:dataMap}"  @success="totalSuccess()" @cancel="totalCancel()"></demandTotal>

    </el-dialog>
    
    <div class="explain_box">
     <el-dialog
    :visible.sync="dialogExp"
  
    :close-on-click-modal ="false"
    :show-close="false"
    class="template_dialog explain"
    >

   <explain v-if="dialogExp"   @cancel="explainClose"></explain>

    </el-dialog>

    <el-dialog
  :visible.sync="dialogTips"
  width="40%"
  class="template_dialog"
  center>
  <div class="tip_content">{{tipType==1?'存在部分户号某些时段的削减容量为零，请确认是否继续提交？':'存在部分户号某些时段的削减容量为空，请重新填报'}}</div>
   <div class="flex_center" v-if="tipType==1">
    <el-button type="warning" @click="dialogTips = false">重新填写</el-button>
    <el-button  type="primary" @click="dialogTips = false;dialogTotal=true">确定提交</el-button>
   </div>
     <div class="flex_center" v-if="tipType==2">
    <el-button type="warning" @click="dialogTips = false">重新填写</el-button>
   </div>
</el-dialog>


<!-- <el-dialog
  :visible.sync="showHit"
  width="30%">
  <span class="dialog_hitBody">您的当前需求邀约已经中标，是否跳转查看中标信息？</span>
  <div class="flex_end dialog_hit">
    <el-button @click="hitNav(0)">否</el-button>
    <el-button type="primary" @click="hitNav(1)">是</el-button>
  </div>
</el-dialog> -->

</div>
   </div>
</template>

<script>
import history from './components/history.vue'
import information from '../information/information.vue'
import demandTotal from './components/demandTotal.vue'
import explain from './components/explain.vue'
import http from '../../../api/url'
export default {
    name:'demandResponse',
data(){
   return{
   
       proportion:60,
       showFlag:false,
   dimension:[
     {label:'请选择',value:null},
     {label:'0%',value:0},
     {label:'5%',value:5},
     {label:'10%',value:10},
     {label:'15%',value:15},
     {label:'20%',value:20},
     {label:'25%',value:25},
     {label:'30%',value:30},
     {label:'35%',value:35},
     {label:'40%',value:40},
     {label:'45%',value:45},
     {label:'50%',value:50},
     {label:'55%',value:55},
     {label:'60%',value:60},
     {label:'65%',value:65},
     {label:'70%',value:70},
     {label:'75%',value:75},
     {label:'80%',value:80},
     {label:'85%',value:85},
     {label:'90%',value:90},
     {label:'95%',value:95},
     {label:'100%',value:100},
    ],
    dialogVisible:false,
    dialoginfor:false,
    companyName:'',
    listData:[],
    array:[],
    dataMap:{},
    choiceId:'',
    state:null,
    saveFlag:false,
    detailLoading:false,
    historyCheck:false,
    inviteIndex:0,
    selectedDate:'',
     formData:{
     responseDetailParameter:{
       customerId:0,
       BeginDemandDate:'',
       EndDemandDate:'',
       IsHistory:true
     },
      pagination:{
        pageSize:999,
        pageIndex:1,
        sort:''
      }
   },
    companyList:[],
    CompanyId:'',
    overdue:false,
    dialogTotal:false,
    dialogExp:false, 
    random:null,
    newColor:'#D4AE6D',
    dialogTips:false,
    tipType:1, //1是数据0，2是数据是空
    showHit:false,
    HasDoneResult:null,
    

    choiceData:{}


       }
     },
      async created (){
  // await this.$axiosMsg()
  let time = this.$moment('2022-12-08 13:00').add(1,'hour').format('YYYY-MM-DD HH:MM');
  this.random = setInterval(()=>{
 
      this.colorRandom()
      },1000)
      },
    beforeDestroy() {
    clearInterval(this.random);
     },
      mounted(){
       
 
      if(Number(localStorage.getItem('zero'))==1){
     this.dialogExp=true

      }else{
    if(this.$route.query.isLogin){
   this.dialogExp=true
    
   }else{
   this.getCompany()
      this.$axiosMsg().then(res=>{
       this.CompanyId=this.$store.getters.GETCompanyId
       if(this.$store.getters.GETCityId=='0'||!this.$store.getters.GETCityId){
          return  this.dialoginfor=true
           }else{
          
          }
       this.getList()
       this.resetCheck()


      })
    
   }
      }

        //不是第一次登录的重刷页面
 



      },
      methods:{
      // 考核基线公式
    baseline(calc,count){
  //  return (calc-(parseFloat(count)*0.5))?((calc-(parseFloat(count)*0.5)).toFixed(1)):'/'
  return calc-count?(calc-count).toFixed(1):'/'
      },
        explainClose(val){
          if(val){
         this.dialogExp=false
         this.$router.push({ query: {} });
         this.getCompany()
        //  this.reset()
      localStorage.setItem('zero',2)
  
          }
   
        },
         colorRandom(){
           //十六进制颜色随机
						let r = Math.floor(Math.random()*256);
						let g = Math.floor(Math.random()*256);
						let b = Math.floor(Math.random()*256);
						this.newColor = '#'+r.toString(16)+g.toString(16)+b.toString(16);
				
					},

          Maximum(count){
            return count?count.toFixed(1):'/'
          },
            reset(){
     
                this.CompanyName = this.$store.getters.GETCompanyName
      
                this.CompanyId = this.$store.getters.GETCompanyId
   
        },
        //申报的详情
       getData(){
        let that=this
      this.detailLoading=true
      let datas={
        demandResponseId:this.choiceId
      }
     
    this.$axiosGet('/api/ResponseDemand/GetResponseDetail',datas).then(res=>{
 
    if(res.Tag==0){
        this.$message.error(res.Message)
    
    }else if(res.Tag==1){
      let current=res.Data
      let maps={
        CompanyName:'暂无',
        RunDate:'暂无',
        remark:''
      }
      if(res.Data.length>0){
        maps.CompanyName=res.Data[0].CompanyName,
        maps.RunDate=res.Data[0].RunDate
        maps.remark=res.Data[0].Remark
       
        current.forEach(val => {
          val.timeOne=[]
          val.timeTwo=[]
          val.timeThree=[]

        for(let i in val.DicResponseTimeVol){
          let num=i,count=0,
           indexs=num.indexOf(":"),
           numAfter=Number(num.substring(indexs+1))/100,
          numBefore=Number(num.substring(0,indexs))
          count=numBefore+numAfter
    
          if(count>=0&&count<12){
            val.timeOne.push({
              key:i+'-'+(count+1)+':00',
              value:parseFloat(val.DicResponseTimeVol[i]),
              count:
              that.HasDoneResult==1?(val.DicResponseDoneResult[i]?val.DicResponseDoneResult[i].EffectiveLoadVol:0)
             :(that.choiceData.historyCheck?val.DicResponseResult[i]:parseFloat(val.DicResponseDetail[i])||parseFloat(val.DicResponseDetail[i])==0?parseFloat(val.DicResponseDetail[i]):''),
              pro:(parseFloat(val.DicResponseDetail[i])/parseFloat(val.DicResponseTimeVol[i])).toFixed(2),
              max:that.HasDoneResult==1?(val.DicResponseDoneResult[i]?val.DicResponseDoneResult[i].AssessmentVol:0):0,
            })
          }
          if(count>=12&&count<=18){
      
            val.timeTwo.push({
              key:i+'-'+(count+1)+':00',
              value:parseFloat(val.DicResponseTimeVol[i]),
              count:that.HasDoneResult==1?(val.DicResponseDoneResult[i]?val.DicResponseDoneResult[i].EffectiveLoadVol:0)
             :(that.choiceData.historyCheck?val.DicResponseResult[i]:parseFloat(val.DicResponseDetail[i])||parseFloat(val.DicResponseDetail[i])==0?parseFloat(val.DicResponseDetail[i]):''),
              pro:(parseFloat(val.DicResponseDetail[i])/parseFloat(val.DicResponseTimeVol[i])).toFixed(2),
              max:that.HasDoneResult==1?(val.DicResponseDoneResult[i]?val.DicResponseDoneResult[i].AssessmentVol:0):0,
            })
          }
          if(count>18&&count<=23.59){
            val.timeThree.push({
              key:count<23?i+'-'+(count+1)+':00':i+'00:00',
              value:parseFloat(val.DicResponseTimeVol[i]),
              count:that.HasDoneResult==1?(val.DicResponseDoneResult[i]?val.DicResponseDoneResult[i].EffectiveLoadVol:0)
             :(that.choiceData.historyCheck?val.DicResponseResult[i]:parseFloat(val.DicResponseDetail[i])||parseFloat(val.DicResponseDetail[i])==0?parseFloat(val.DicResponseDetail[i]):''),
              pro:(parseFloat(val.DicResponseDetail[i])/parseFloat(val.DicResponseTimeVol[i])).toFixed(2),
               max:that.HasDoneResult==1?(val.DicResponseDoneResult[i]?val.DicResponseDoneResult[i].AssessmentVol:0):0,
            })
          }
        }

        });

         current.forEach(val => {
             val.timeAll=val.timeOne.concat(val.timeTwo).concat( val.timeThree)
             val.showFlag=true
              val.proportion=Number(((val.timeAll[0].pro)*100).toFixed(2))
         })

             if(current.length>0){
            let flag=true,
            arrays=current[0].timeAll;
           current.forEach(objs=> {
       
     
          flag= arrays.every((item,indexEve) => { return objs.timeAll[indexEve].pro!=item.pro })
           })
           if(flag){
          this.proportion=null

           }else{
         this.proportion=current[0].proportion
           }
         
            current.forEach(objs=> {
      
           objs.timeAll.some(tol=>{
           
              // tol.max=this.Maximum(tol.value-(tol.count*0.5))
                  if(that.HasDoneResult==1){
                  }else{
                 tol.max=this.baseline(tol.value,tol.count)

                  }
              // tol.max=this.Maximum(tol.value-tol.count)
            if(objs.proportion!=(Number((tol.pro)*100).toFixed(2))){
               this.proportion=null
            return  objs.proportion=null
            }
     
             })
          
  
            })

          
          

  

        }

      }
       console.log(current)
      this.array=current
      this.dataMap=maps
 

    }else{

    }

      this.detailLoading=false
  
    })
    

       },
      //  获取邀约信息列表
       getList(){
          let that = this;
        this.$axiosGet("/api/ResponseDemand/GetInviteDemandInfos", {}).then(
            (res) => {
            if (res.Tag == 1) {
              
                let current = res.Data;
                current.forEach((val) => {
                val.surplus = this.differentTime(
                    this.getCurrentTime("/"),
                    val.EndDeclareTime.replace(/-/g, "/")
                );  
              if(val.ResponseTypeVal==4||val.ResponseTypeVal==6){
                 val.invalid=false
                  }else{
                 if(Number(val.TimeStamp)-(parseInt(new Date().getTime() / 1000))>0){
                val.invalid=true
                

              }else{
                 val.invalid=false 

              }
                  }
            
     
            val.isHandle=false //是否可输入        
            val.historyCheck=false //是否是历史   // 是否可用输入
            if(val.invalid){
            val.isHandle=val.ResponseTypeVal==-1||val.ResponseTypeVal==1||val.ResponseTypeVal==4?true:false
            }else{
              val.isHandle=false
            }
                });
             
               
                this.listData = current;
               
                if(this.listData.length>0){

                    this.choiceId=this.listData[0].ResponseComandId
                 
                    // this.state=this.listData[0].ResponseTypeVal==3||this.listData[0].ResponseTypeVal==4||this.listData[0].ResponseTypeVal==6?this.listData[0].ResponseType:(this.listData[0].invalid?this.listData[0].ResponseType:'已过期')
                    this.state=this.listData[0].ResponseType
                     this.choiceData=this.listData[0]
                     this.HasDoneResult=this.listData[0].HasDoneResult
                     if(this.choiceData.ResponseTypeVal==4 || this.choiceData.ResponseTypeVal==5){
                        //  this.showHit=true
                        this.choiceData.historyCheck=true
                     }
                    
  
                

                    this.inviteIndex=0
                    
                    this.getData()
                }
            }
               this.detailLoading=false
            }
        );
       },

       inputKey(index,key){

         this.array[index].timeAll.forEach(res=>{
           if(res.key==key){
     
             if(Number(res.count)<0){
               res.count=0
             }else if(Number(res.count)>res.value){
               res.count=res.value
               this.$message.warning('削减容量不能大于基线负荷！')

             }else{

             }
             
         res.max=this.baseline(res.value,res.count)
          //  res.max=(res.value-(parseFloat(res.count)*.5))?((res.value-(parseFloat(res.count)*.5)).toFixed(1)):'/'

           }
         })


  
      
       },
      async  updataCompany(){
        this.dialoginfor=false
      await this.$axiosMsg().then(res=>{
       this.CompanyId=this.$store.getters.GETCompanyId
       this.getList()

      })

      this.resetCheck()
      this.proportion=60
        },
    openDetails(flag,index){
      
        // this.array.forEach(res => {
        //  res.showFlag=false
        // });
         this.array[index].showFlag=!flag
     


    },
    choosePicker(type,index){
        this.propChange(type,index)
    },
        propChange(type,index){

      let that=this,
     total=Number(that.proportion)/100,
     currentArr= JSON.parse(JSON.stringify(that.array)); 
     let  calc= (cal,pro)=>{
        
        return parseFloat(Number(cal)*pro)<0?0:(Number(cal)*pro)

      }
       
       //每项削减比例
      if(type==1){
     currentArr.forEach((val,arrIndex) => {
       if(arrIndex==index){
      let prop=Number(val.proportion)/100
          val.timeAll.forEach((res) => {
          
         res.count=(calc(res.value,prop)).toFixed(1)

        res.max=this.baseline(res.value,calc(res.value,prop))
        // res.max=(res.value-(calc(res.value,prop)*0.5))?((res.value-(calc(res.value,prop)*0.5)).toFixed(1)):'/'


        
      });
      
       }

      
    });
    console.log(currentArr)
      }else{

     currentArr.forEach(val => {
        val.proportion=that.proportion
          val.timeAll.forEach(res => {
        res.count=(calc(res.value,total)).toFixed(1)

        res.max=this.baseline(res.value,calc(res.value,total))
        // res.max=(res.value-(calc(res.value,total)*0.5))?((res.value-(calc(res.value,total)*0.5)).toFixed(1)):'/'

      });
      

      
    });
      }

  



      that.array = currentArr;
    },


    switchInvite(e,index){

       this.choiceData=e
       this.choiceId=e.ResponseComandId
      this.proportion=60
      this.inviteIndex=index
        
       console.log(e)
       this.HasDoneResult=e.HasDoneResult
      //  if(e.ResponseType=="已取消"||e.ResponseType=="已中标"){
      //    this.state=e.ResponseType
      //  }else{
      // if(e.invalid){
      //    this.state=e.ResponseType
      // }else{  
      //     this.state="已过期"
      // }
      //  }

     this.state=e.ResponseType
     if(e.ResponseTypeVal==4||e.ResponseTypeVal==5){
      this.hitNav(1)
     }else{
      this.getData()
     }
 
   
  
    },
    //中标
    hitNav(e){
    
      if(e){
       this.choiceData.historyCheck=true
      // this.overdue=false //不显示历史数据

      }else{
         this.choiceData.historyCheck=false
      // this.overdue=true //显示历史数据

      }
      console.log(this.choiceData)
       this.inviteIndex=this.listData.findIndex(res=>{return res.ResponseComandId==this.choiceData.ResponseComandId})
        this.state= this.choiceData.ResponseType

      this.proportion=60
      this.getData()
      // this.showHit=false
    },

    
    check(val){
        console.log(val)
        this.historyCheck=val.historyCheck
        this.choiceId=val.ResponseComandId
        this.getData()
        this.dialogVisible=false
    },
    resetCheck(){
    this.historyCheck=false
    this.selectedDate=''
    // this.overdue=false
    this.getList()
    },

    dateChange(){
    this.historyCheck=true
      this.array=[]
      this.proportion=''
      this.inviteIndex=null
      this.choiceId=''
      this.state=''
      this.listData=[],
      this.overdue=true
   this.getHistory()
 
   },
    getHistory(){
      let datas=this.formData
      console.log(this.$qs.stringify(datas))
      datas.responseDetailParameter.BeginDemandDate=this.$moment(this.selectedDate).startOf("month").format("YYYY-MM-DD")
      datas.responseDetailParameter.EndDemandDate=this.$moment(this.selectedDate).endOf('month').format("YYYY-MM-DD")
      this.$axiosPost('/api/ResponseDemand/GetDemandReponseDetailPageList',datas,{accept:'*/*'}).then(res=>{
     
        if(res.Tag==1){
         let current=res.Data
             if(current.length>0){
                  current.forEach(val => {
                    val.historyCheck=true
             if(Number(val.TimeStamp)-(parseInt(new Date().getTime() / 1000))>0){
                val.invalid=true

              }else{
                 val.invalid=false

              }
                    });
               }
               this.listData=current
              if(this.listData.length>0){
                    this.choiceId=this.listData[0].ResponseComandId
                    //  this.state=this.listData[0].invalid?this.listData[0].ResponseType:'已过期'
                     this.state=this.listData[0].ResponseType
                    this.inviteIndex=0
                    this.historyCheck = true
                    this.choiceData=this.listData[0]
               
                    this.getData()
              
                }

        }else{
          this.$message.error(res.Message)
        }
      })
    
    
   },
     // 切换token
    async changeToken(val) {
           this.detailLoading=true
      this.$axiosGet("/api/User/ChangeLoginFromForm", { customerId: val }).then(
        (res) => {
          if (res.Tag == 1) {
       

           

            localStorage.setItem("token", res.Data);
               this.listData=[]
               this.array=[]
                this.$axiosMsg().then(res=>{
                 if(this.$store.getters.GETCityId=='0'||!this.$store.getters.GETCityId){
                 return  this.dialoginfor=true
               }else{
            

               }
                  this.getList()
                this.resetCheck()
                })
          
        
                this.proportion=60
               this.inviteIndex=0
        
      
     
             
          }else{
            this.$message.error(res.Message)
          }
        }
      );
    },
    //获取企业列表
  getCompany(){
  
    this.$axiosPost('/api/ResponseDemand/GetReponseCompanyList').then(res=>{

      if(res.Tag==1){
        let dataList=JSON.parse(JSON.stringify(res.Data))
        if(dataList.length>0){
         console.log(dataList.length)
          if(dataList.length==1){
          this.CompanyId=dataList[0].Id
          // this.changeToken(this.CompanyId)
            if(this.$route.query.isLogin){
              if(this.$store.getters.GETCityId=='0'||!this.$store.getters.GETCityId){
              return  this.dialoginfor=true
              }else{
              
              }
            }

         this.getList()
          }
           
        // this.$axiosMsg()
      // this.CompanyId=this.$store.getters.GETCompanyId
      
       this.companyList=dataList
    
    
        }
      
      }
    })

  },
  
  totalCancel(){
this.dialogTotal=false
  },
  totalSuccess(){
this.dialogTotal=false

 this.getList()
  },
  saveTotal(){
let flag=false



 this.array.forEach(val => {
    val.timeAll.some(rep => {
 if(parseFloat(rep.max)==rep.value){
       this.tipType=1
        return flag=true
      }
      });

  });
 this.array.forEach(val => {
    val.timeAll.some(rep => {
      let currentCount=String(rep.count)
      if(!currentCount){
       this.tipType=2
        return flag=true
      }
        
      });

  });





  if(flag){
  this.dialogTips=true

  }else{
this.dialogTotal=true

  }


  },
  //  获取下载链接
     exportResult(){
   console.log(this.choiceData)
   console.log(this.array)
   
        if(this.choiceData.ResponseType=='不参与'||this.choiceData.ResponseType=='未中标'||this.choiceData.ResponseType=='已取消'){

          this.$message.warning('您当前邀约'+this.choiceData.ResponseType+'，无执行结果详情！')
      }else{
        let datas={
               ResponseDeamdId:this.array.length>0?this.array[0].ResponseComandId:'',
               CustomerId:this.array.length>0?this.array[0].CustomerId:'',
               BeginDemandDate:this.array.length>0?(this.array[0].RunDate&&this.array[0].RunDate!='请选择运行日期'?this.array[0].RunDate+' 00:00:00':''):'',
               EndDemandDate:this.array.length>0?(this.array[0].RunDate&&this.array[0].RunDate!='请选择运行日期'?this.array[0].RunDate+' 23:59:59':''):'',
            }
        
            this.$axiosPost('/api/ResponseDemandForEmployee/GetDemandDoneResult',datas).then(res=>{
                console.log(res)

              if(res.Tag==1){
                let reg=/\.{1}[A-Za-z]{1,}$/;
              let maps={
                // Description:'广东蚂蚁金谷能源科技需求响应结果_'+this.dataMap.RunDate+(reg.exec(res.Data)?reg.exec(res.Data)[0]:'.xls'),
                Description:this.dataMap.RunDate+'_'+(this.array.length>0?this.array[0].CompanyName:'')+'_响应执行结果'+(reg.exec(res.Data)?reg.exec(res.Data)[0]:'.xls'),
                Data:http.baseURL+'/'+res.Data
      
              }
           this.downloadFile(maps)
              }else{
             
                 this.$message.error(res.Message)
              }

            })

    
      }
   },
  //下载文件
  downloadFile(val){

  this.downloadUrlFn(val.Data,val.Description)

    }

      },
  components: {
     history,
     information,
     demandTotal,
     explain
  }


}
</script>

<style lang="scss" scoped>
.container{
    // width: 1656px;
//  height:calc(100vh - 110px);
 background: #fff;
 font-size: 16px;
font-family: PingFang SC-Regular, PingFang SC;
font-weight: 400;

}
:deep(.el-dialog__body){
    padding-top: 0;
}
.explain_box{
   background:url('../../../assets/img/shading.png') no-repeat;
   background-size:100% 100%;
   background: white;
}
.explain{

  :deep(.el-dialog__header){
        padding: 0;
    }
} 
.content{
     padding: 30px;
.title{
    font-size: 18px;
    width: 100%;
    height: 30px;
   position: relative;
  padding-left: 20px;
  padding-bottom: 16px;
font-family: PingFang SC-Bold, PingFang SC;
font-weight: bold;
color: #2E353E;
border-bottom: 1px solid #DDE2E6;
display: flex;
align-items: center;
position: relative;
   &:before {
    content: "";
    width: 6px;
    height: 20px;
    background-color: #3e96fc;
    border-radius: 2px;
    display: block;
    position: absolute;
    left: 5px;
    top: 6px;
  }
}
.titleName{
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #808992;
        padding: 16px 0;
        margin-right: 10px;
}
.action{
  margin:24px 0;
   margin-bottom: 44px;

   .input_company{
   margin-right:40px;
   width:588px;
   }
   .change{
       font-size: 18px;
font-family: PingFang SC-Bold, PingFang SC;
font-weight: bold;
color: #2E353E;
padding: 7px 14px;
   }
   :deep(.el-icon-sort){
   transform: rotate(90deg);
   }
}
.description{
        width: 100%;
        font-size: 18px;
        font-family: Arial-Bold, Arial;
        font-weight: bold;
        color: #2E353E;
    .date{
    color:#498FFB;
    margin-right: 50px;
    
    }
  .circular{
      border-radius: 50%;
      width: 10px;
      height: 10px;
      margin-right: 5px;
      margin-left: 35px;
  }
  .tip{
      font-size: 12px;
font-family: PingFang SC-Regular, PingFang SC;
font-weight: 400;
color: #808992;
margin-right: 56px;
  }

}
.response_box{
    display: flex;
    justify-content: space-between;
       margin-top: 22px;
}
.invite{
    border: 1px solid #DDE2E6;
    font-size: 16px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    border-radius: 8px;
    color: #808992;


    .invite_item{
    display: flex;
    flex-direction: column;
    
     padding: 24px 20px;

    .item_label{
        width: 115px;
    }

    .item_date{
        font-size: 16px;
        font-family: PingFang SC-Bold, PingFang SC;
        font-weight: bold;
        color: #2E353E;
    }

    }
    .invite_item:hover{
cursor:pointer ;
background: #DAE9FE;
    }
    .invite_item_choose{
      
background:rgba(26, 143, 240, 0.247);


    }
    .invite_item_title{
          display: flex;
    flex-direction: column;
     padding: 24px 20px;
        cursor:default;
background:transparent;
  
    }
}
    .invite_title{
     font-size: 18px;
      color: #5D6978;

    }
.response_details{
    width: 80%;
    // height: 600px;
    // padding: 24px 0;
    border-radius:8px;
    font-size: 18px;
    font-family: Arial-Bold, Arial;
    font-weight: bold;
    color: #2E353E;
    // border: 1px solid #DDE2E6;
 
    .response_title{
     font-size: 18px;
    font-family: PingFang SC-Bold, PingFang SC;
    font-weight: bold;
    color: #5D6978;
    }

}
.response_details_container{
  max-height: 580px;
   
  overflow-y: auto;
  overflow-y: overlay;
    box-sizing: border-box;
  //   &::-webkit-scrollbar-thumb{
  //    background: #07C160 !important;
  //    border-radius: 10px;
  //  }
      &::-webkit-scrollbar {
        /*滚动条整体样式*/
        width : 10px;  /*高宽分别对应横竖滚动条的尺寸*/
        // height: 12px;
        }
        &::-webkit-scrollbar-thumb {
        /*滚动条里面小方块*/
        border-radius: 5px;
        box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.1);
        background   :#498FFB;
        // rgba(26, 143, 240, 0.247)
        }
        &::-webkit-scrollbar-track {
        /*滚动条里面轨道*/
        box-shadow   : inset 0 0 5px rgba(0, 0, 0, 0.1);
        background   : #ededed;
        }
  
}
.news_box{
  padding: 0 10px;
  padding-left: 40px;
}
.news{
  font-size: 17px;
font-family: PingFang SC-Regular, PingFang SC;
font-weight: 400;

}

.response_details_item{
  span{
    padding: 0 24px;
         font-size: 18px;
    font-family: Arial-Bold, Arial;
    font-weight: bold;
    color: #2E353E;
  }

}
.response_details_all{
    border-radius: 8px;
    // margin-bottom: 24px;
    border: 1px solid #DDE2E6;

}
.response_details_item_title{
    padding: 20px 0;
    // padding-right:38px;
    font-size: 15px;
    font-family: Arial-Bold, Arial;
    font-weight: bold;
    color: #2E353E;

    background: #ecececc7;
}
.response_details_item_time{
    display: flex;
    flex-direction: column;
    .item_labels{
        padding: 10px 0;
        width: 100%;
        height: 25px;
        line-height: 25px;
        text-align: center;
        position: relative;
          font-size: 18px;
          font-family: Arial-Bold, Arial;
          font-weight: bold;
          color: #2E353E;

        
    }
      .item_input{
        padding: 10px 0;
        height: 25px;

        :deep(.el-input__suffix){
          display: flex;
          align-items: center;
      }
       
    }
    .open_icon{
        margin-left: 35px;
      width: 24px;
      height: 24px;
      position: absolute;
      top: 50%;
      right: 0;
      margin-top: -12px;
    }
}

}
.bg_red{
    background: red;
}
.bg_green{
background:#07C160
}
.bg_gray{
background:#808992
}
.line{
    width: 100%;
    height: 1px;
    background: #DDE2E6;
    margin: 15px 0;
}

.table_line{
     width: 100%;
    height: 1px;
    background: lightgray;
   

}
.dw{
 font-size: 16px;
font-family: PingFang SC-Bold, PingFang SC;
font-weight: bold;
color: #5D6978;
}
.invite_title_label{
text-align: center;
}
:deep(.num_input input::-webkit-outer-spin-button),:deep(input::-webkit-inner-spin-button){
    -webkit-appearance: none!important;
  }
  :deep(.num_input input[type="number"]){
    -moz-appearance: textfield ;
    -webkit-appearance: textfield;
    appearance: textfield;
    font-size: 16px;
    font-family: Arial-Bold, Arial;
    font-weight: bold;
    color: #2E353E;
    height: 35px;
    padding: 10px 5px;
    text-align: center;
  }
   .emty{
     width: 272.61px;
   }
.month_picker{
.el-date-picker {
  width: 220px;
  height: 200px;
  &__header-label {
    font-size:14px;
  }
}

 
   }
   .input_prop{
    display: flex;
    justify-content: space-between;
    align-items: center;
    :deep(.el-input__inner){
     font-size: 18px;
    font-family: Arial-Bold, Arial;
    font-weight: bold;
    color: #2E353E;
    height: 35px;

    }
    :deep(.el-input__suffix){
      display: flex;
      justify-content: center;
      align-items: center;
    }
   }
 .response_All{

  :deep(.el-input__inner){
    padding: 0 10px ;
         font-size: 18px;
    font-family: Arial-Bold, Arial;
    font-weight: bold;
    color: #2E353E;
  }

}
.tip_content{
       font-size: 18px;
    font-family: Arial-Bold, Arial;
    font-weight: bold;
    color: #2E353E;
  margin: 50px auto;
  text-align: center;
}
.template_dialog{
&:deep(.el-dialog){
  display: flex;
  flex-direction: column;
  margin:0 !important;
  position:absolute;
  top:50%;
  left:50%;
  transform:translate(-50%,-50%);
  /*height:600px;*/
  max-height:calc(100% - 30px);
  max-width:calc(100% -30px);

  //  .el-dialog__body{
  //   // padding: 0;

  //   }

}
}
.dialog_hit{
  margin-top: 20px;

}
.dialog_hitBody{
  padding: 15px 0;
  font-size: 16px;
}
:deep(.el-dialog){
    display: flex;
    flex-direction: column;
    margin: 0 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: calc(100% - 30px);
    max-width: calc(100% - 30px);
  }

  :deep(.el-date-picker__header){
     display: flex;
     justify-content: space-between;
     align-items: center;
  }
  :deep(.el-date-picker__header .el-picker-panel__icon-btn){
    margin-top: 0;
  }
  
</style>