<template>
   <div class="container_his">
        <div class="content ">
          <div class="title flex_between">
            <span>历史响应数据</span>
         <div class="flex_start">
            <img src="../../../../assets/timeSelect.png" />
                <el-date-picker
                v-model="selectedDate"
                 type="month"
                placeholder="选择日期"
                @change="dateChange"
               value-format=yyyy-MM-dd
               :clearable="false"
                >
                </el-date-picker>
          </div>
            
            </div>
            <div class="his_title flex_between">
           <div ><span>响应日期</span></div>
           <div ><span>响应日期</span></div>
           <div ><span>响应日期</span></div>


            </div>
            <div class="his_content_box">
            <div class="his_title_content flex_between" v-for="item in listData" :key="item.ResponseComandId">
           <div ><span>{{item.RunDate}}</span></div>
           <div ><span>{{item.ResponseType}}</span></div>
           <div ><el-button class="check_btn" type="primary" v-if="item.ResponseTypeVal==1" @click="check(item)">查询</el-button><span v-else>/</span></div>
            </div>

 
         </div>
        
       <div class="foot_nav flex_center">
     <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="formData.pagination.pageIndex"
      :page-sizes="[100, 200, 300, 400]"
      :page-size="100"
      layout="total, sizes, prev, pager, next, jumper"
      :total="listTotal"
      >
    </el-pagination>
    </div>
        </div>
   </div>
</template>

<script>

export default {
  name:'history',
data(){
   return{

   selectedDate:'',
   listTotal:0,
   formData:{
     responseDetailParameter:{
       customerId:0,
       BeginDemandDate:'',
       EndDemandDate:'',
     },
      pagination:{
        pageSize:10,
        pageIndex:1,
        sort:''
      }
   },
   listData:[]
   }
     },
     created (){
   this.selectedDate=this.$moment().startOf("month").format("YYYY-MM-DD")
      },
      mounted(){
     
              this.getHistory()
      },
      methods:{

   getHistory(){
      let datas=this.formData
      datas.responseDetailParameter.BeginDemandDate=this.$moment(this.selectedDate).startOf("month").format("YYYY-MM-DD")
      datas.responseDetailParameter.EndDemandDate=this.$moment(this.selectedDate).endOf('month').format("YYYY-MM-DD")
      this.$axiosPost('/api/ResponseDemand/GetDemandReponseDetailPageList',datas,{accept:'*/*'}).then(res=>{
     
        if(res.Tag==1){
         let current=res.Data
           this.listData=current
           this.listTotal=res.Total
        
        }
      })
    
    
   },
   dateChange(){
  
   this.getHistory()
 
   },
   check(val){
     let datas=val
     datas.historyCheck=true
    this.$emit('check',datas)
   },
   handleSizeChange(){

   },
   handleCurrentChange(){

   },
    currentPage(){

    }

      },



}
</script>

<style lang="scss" scoped>

.container_his{
//     width: 1656px;
//  height:calc(100vh - 110px);
width: 100%;
 background: #fff;

}
.content{
     padding: 30px;
    //  padding-top: 0;
 
     
.title{
    font-size: 18px;
    width: 100%;
    height: 30px;
   position: relative;
  padding-left: 20px;
font-family: PingFang SC-Bold, PingFang SC;
font-weight: bold;
color: #2E353E;
padding-bottom: 16px;
border-bottom: 1px solid #DDE2E6;
display: flex;
align-items: center;
position: relative;
   &:before {
    content: "";
    width: 6px;
    height: 20px;
    background-color: #3e96fc;
    border-radius: 2px;
    display: block;
    position: absolute;
    left: 5px;
    top: 6px;
  }
}

}

.his_title{
font-size: 16px;
font-family: PingFang SC-Regular, PingFang SC;
font-weight: 400;
color: #5D6978;
border: 1px solid #DDE2E6;
background: #F8F9F9;
margin-top: 24px;
   div{
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    

}
}
.his_title_content{
    border: 0;
       div{
    width: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 0;
    

}

}
.his_content_box{
    max-height: 300px;
  overflow-x: hidden;
overflow-y: scroll;
}
.foot_nav{
  margin-top: 40px;
}
</style>