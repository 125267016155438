<template>
   <div :class="type==1?'container_assembly':'container_info'">
        <div class="content_info" >
          <div class="title"><span>基本信息</span></div>
          <div class="edit flex_end" :style="type==1?'margin:0':''" >
              <el-button v-if="type!=1" type="primary" v-show="editFlag" icon="el-icon-edit" @click="editFlag=!editFlag">编辑</el-button>
          </div>
        
              <el-row class="form"  :gutter="24">
              <el-col :span="12" class="form_tip"><span class="color_red">*</span>企业名称</el-col>
              <el-col :span="12" class="form_tip">交易代码</el-col>
              <el-col :span="12"  class="form_input">
                     <el-select :disabled="editFlag&&type!=1" @change="changeToken"   class="elinput" v-model="CompanyId" placeholder="请选择">
                        <el-option
                      v-for="item in companyList"
                        :key="item.Id"
                        :label="item.CompanyName"
                        :value="item.Id"
                    >
                    
                      <div class="flex_between"><span>{{item.CompanyName}}</span><span :class="item.IsResponse=='-1'?'color_red':'color_green'">{{item.IsResponse=='-1'?'未申报':'已申报'}}</span></div>
                    <!-- <div class="flex_between"><span>{{item.CompanyName}}</span><span :class="item.IsResponse=='1'?'color_green':'color_red'">{{item.IsResponse=='1'?'已申报':'未申报'}}</span></div> -->
                        </el-option>
                    </el-select>
              </el-col>
              <el-col :span="12"  class="form_input">
                     <el-select  disabled  class="elinput" v-model="CompanyCode" placeholder="请选择">
                        <el-option
                      v-for="item in companyList"
                        :key="item.Id"
                        :label="item.CompanyName"
                        :value="item.Id"
                    >
                        </el-option>
                    </el-select>

              </el-col>

              <el-col :span="12" class="form_tip"><span class="color_red">*</span>所在城市</el-col>
              <el-col :span="12" class="form_tip"><span class="color_red">*</span>用电性质</el-col>
              <el-col :span="12"  class="form_input">

                
                     <el-cascader
                      class="elinput"
                      :disabled="editFlag&&type!=1"
                      v-model="chooseCity"
                    :options="citylistData[0]"
                    @change="handleChange"
                    :props="{label:'value',value:'label'}"
                    >
                    </el-cascader> 
          
              
           
              </el-col>
              <el-col :span="12"  class="form_input">
                    <el-select  :disabled="editFlag&&type!=1" class="elinput" v-model="ElectricalProperty" placeholder="请选择">
                        <el-option
                      v-for="item in typeList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    >
                        </el-option>
                    </el-select>

              </el-col>

             <el-col :span="12" class="form_tip"><span class="color_red">*</span>所属产业</el-col>
              <el-col :span="12" class="form_tip"><span class="color_red">*</span>所属行业</el-col>
              <el-col :span="12"  class="form_input">
                        <el-select  :disabled="editFlag&&type!=1" class="elinput" v-model="BigIndustryType" placeholder="请选择">
                        <el-option
                      v-for="item in bigList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    >
                        </el-option>
                    </el-select>
                    
              </el-col>
              <el-col :span="12"  class="form_input">
                              <el-select :disabled="editFlag&&type!=1"  class="elinput" v-model="SmallIndustryType" placeholder="请选择">
                        <el-option
                      v-for="item in smallList"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id"
                    >
                        </el-option>
                    </el-select>

              </el-col>

                <el-col :span="24" class="form_tip">主营业务</el-col>
               <el-col :span="24"  class="form_input">
  
             <el-input
             style="width:100%"
              class="elinput"
              :disabled="editFlag&&type!=1"
                    type="textarea"
                    :rows="5"
                    placeholder="请填写企业主营业务"
                    v-model="remark">
                    </el-input>

              </el-col>

               <el-col   v-if="!editFlag&&type!=1" :span="24"  class="form_input flex_center">
                <el-button type="primary" @click="saveInfo">确定</el-button>
                  <el-button @click="editFlag=true">取消</el-button>
                  
               </el-col>
                 <el-col  v-if="type==1" :span="24"  class="form_input flex_center">
                  <el-button @click="reset">重置</el-button>
                <el-button type="primary" @click="saveInfo">确定</el-button>
           
                  
               </el-col>
              </el-row>
         
        </div>
   </div>
</template>

<script>
var CityData=[]
    var CityData1=[]
    var CountyData = [];
export default {
  name:"information",
      props: {
          type:Number
      },
data(){
   return{

    chooseId:'',
    companyList:[],
    menuList:[
    {
    label:'服务',
    value:0,
    children:[
   { 
    label:'需求响应',
    value:1,
    url:''

  }]
    },{
    label:'我的',
    value:2,
    children:[
   { 
    label:'企业信息',
    value:3,
    url:'/information'
   },
    { 
    label:'绑定微信',
    value:4,
   },
   ]
    }
    ],

    citylistData:[],
    CompanyName:null,
    CompanyCode:null,
    ElectricalProperty:null,
    BigIndustryType:null,
    SmallIndustryType:null,
    ProvinceId:0,
    CityId:0,
    CountyId:0,
    CompanyId:'',
    chooseCity:[],

     defaultValueDataCity: [],

     showCity:false,
     cityIndex:0,
     showType:false,
     typeList:[],
     typeIndex:0,
     bigList:[],
     bigIndex:0,

     showBig:false,
     smallList:[],
     smallIndex:0,
     showSmall:false,
     remark:'',
     saveFlag:false,
     editFlag:true,


    
            }
     },
      async created (){
          console.log(this.type)
        
   await  this.$axiosMsg()
           this.reset()
         
     
                      this.getCompany()
                this.showproperty()
                this.getArea()
                this.getBig()
      },
      mounted(){

                this.getCompany()
      },
      methods:{
        reset(){
     
                this.CompanyName = this.$store.getters.GETCompanyName
                this.CompanyCode = this.$store.getters.GETCompanyCode
                this.CompanyId = this.$store.getters.GETCompanyId
                this.ElectricalProperty = String(this.$store.getters.GETElectricalProperty)
                this.BigIndustryType = String(this.$store.getters.GETBigIndustryType)
                this.SmallIndustryType = String(this.$store.getters.GETSmallIndustryType)
                this.ProvinceId = this.$store.getters.GETProvinceId
                this.CityId = this.$store.getters.GETCityId
                this.CountyId = this.$store.getters.GETCountyId
                this.remark = this.$store.getters.GETremark
                this.chooseCity=[this.CityId,this.CountyId]
        },
       //获取企业列表
  getCompany(){
  
    this.$axiosPost('/api/ResponseDemand/GetReponseCompanyList').then(res=>{

      if(res.Tag==1){
        this.companyList=res.Data
      }
    })

  },
    //获取区域
  getArea(){

  if (CountyData.length == 0) {
      this.$axiosPost('/api/ComBox/GetAreaListFromForm',{parentId:0}).then(res=>{

    if(res.Tag==1){

      
                            var list = res.Data
                            var city = []
                            var result = list.filter(item => item.ParentAreaCode == list[0].AreaCode)
                            CityData=result
                            // city.push({label: 0,value: "请选择"})
                            for (var i = 0; i < result.length; i++) {
                                city.push({label: result[i].AreaCode, value: result[i].AreaName})
                                var array = list.filter(item => item.ParentAreaCode == result[i].AreaCode)
                                var county = []
                                for (var d in array) {

                                    county.push({label: array[d].AreaCode, value: array[d].AreaName})

                                }
                                CountyData.push({label: result[i].AreaCode, array: county})

                            }
                   
                               CityData1=city
                            
                            this.citylistData.push(city)
                    
                               city.forEach(val => {
                              
                                if(val.label==0){
                                 val.children=[{label:0,value:''}]
                                }else{
                                 val.children=[]
                                }
                         
                                let arry = list.filter(item => (item.ParentAreaCode == val.label)&&item.ParentAreaCode!='0')
                               
                                   arry.forEach(ele=>{
                                     
                                  
                                     val.children.push({label:ele.AreaCode,value:ele.AreaName})
                                    
               

                                   })
                          
                           

                                 
                               });
                
                            if (this.CityId != 0) {
                                if (this.CountyId == 0) {
                                    var a = city.find(item => item.label == this.CityId)
                                    var b = CountyData.find(item => item.label == this.CityId)

                                    this.defaultValueDataCity = [{label: a.label, value: a.value}, {
                                        label: b.label,
                                        value: b.value
                                    }]
                                    this.cityoption= a.value+"-"
                                    

                                }
                                else {
                              city.find((item,index)=>{
                                   item.label == this.CityId
                                   if(  item.label == this.CityId){
                                 this.cityIndex=index
                                   }
                                  
                                  })
                                  
                                    var a = city.find((item) => item.label == this.CityId)

                                    var b = CountyData.find(item => item.label == this.CityId).array.find(item=> item.label == this.CountyId   )
                                        
                        
                                    this.defaultValueDataCity = [{label: a.label, value: a.value}, {
                                        label: b.label,
                                        value: b.value
                                    }]
                             if(this.citylistData.length==0){
                                 this.citylistData[0]=CityData1
                               }
                                    this.cityoption=a.value+"-"+b.value
                                 
                                }
               

                            }

   
        
             console.log(this.citylistData)
                            }
                          
                })
      
               } else{
                       if(this.citylistData.length==0){
                        this.citylistData[0]=CityData1
                    }
                    if (this.CityId != 0) {
                        if (this.CountyId == 0) {

                            var a = CityData.find(item => item.AreaCode == this.CityId)
                            var b = CountyData.find(item => item.label == this.CityId)

                            this.defaultValueDataCity = [{label: a.AreaCode, value: a.AreaName}, {
                                label: b.label,
                                value: b.value
                            }]
                            this.cityoption= a.AreaName+"-"
                     

                        }
                        else {

                            var a = CityData.find(item => item.AreaCode == this.CityId)

                            var b = CountyData.find(item => item.label == this.CityId).array.find(item => item.label == this.CountyId)

                            this.defaultValueDataCity = [{label: a.AreaCode, value: a.AreaName}, {
                                label: b.label,
                                value: b.value
                            }]
                            this.cityoption=a.AreaName+"-"+b.value


                           
           }


                    }
                    else{

                        this.defaultValueDataCity=[]

                        this.cityoption="请选择"
                       
                    }
                       
                        }

  },
  //选择
  chooseMenu(e){

    this.chooseId=e.value
    this.$router.push(e.url)

  },
  handleChange(e){

  this.CityId=e[0]
  this.CountyId=e[1]
 this.chooseCity=[e[0],e[1]]

 console.log(this.chooseCity)
  },

    // 获取用电性质
  showproperty(){
    this.$axiosGet('/api/ComBox/GetElectricalPropertys',{parentId:0}).then(res=>{
      if(res.Tag==1){
        this.typeList=res.Data

        this.typeList.unshift({id: 0, name:"请选择"})

                 this.typeList.find((item,index)=> {
                 if(item.id==this.ElectricalProperty){
                     this.propertype=item.name
                     this.typeIndex=index
                 }
               })
   
      }

    })
  },

    getBig(){
   let datas={parentId:0}
  this.$axiosPost('/api/ComBox/GetInstryListFromForm',this.$qs.stringify(datas)).then(res=>{
   
    if(res.Tag==1){
  this.bigList=res.Data
    this.bigList.unshift({id: 0, name:"请选择"})
  if(this.BigIndustryType){
        


        this.getSmall()
  }
   
    }
  
  })
  },
   getSmall(){
   let datas={
parentId:this.BigIndustryType
   }
   
  this.$axiosPost('/api/ComBox/GetInstryListFromForm',this.$qs.stringify(datas)).then(res=>{

    if(res.Tag==1){
  this.smallList=res.Data
    this.smallList.unshift({id: 0, name:"请选择"})

               this.smallList.find((item,index)=> {
                 if(item.id==this.SmallIndustryType){
                   
                     this.smalloption=item.name
                     this.smallIndex=index
                 }
               })
    }
  
  })
  },


   saveInfo(){
    

      if ((!this.CityId||!Number(this.CityId)) && (!this.CountyId||!Number(this.CountyId))) {
        // return   this.$message({ message: '请选择所在城市',type: 'warning'});
          return this.$message.warning('请选择所在城市');

         } 

         if (!this.ElectricalProperty) {

        // return   this.$message({ message: '请选择用电性质',type: 'warning'});
          return this.$message.warning('请选择用电性质');

                    }
          if (!this.BigIndustryType) {
  
          return this.$message.warning('请选择所属产业');



                    }
          if (!this.SmallIndustryType) {
          return this.$message.warning('请选择所属行业');


                    }
          if (!this.remark) {
          return this.$message.warning('请填写主营业务');


                    }
       this.saveFlag=true

                 let datas={
                          "companyName": this.CompanyName,
                            "companyCode": this.CompanyCode,
                            "electricalProperty": this.ElectricalProperty,
                            "bigIndustryType": this.BigIndustryType,
                            "smallIndustryType": this.SmallIndustryType,
                            "provinceId": 440000,
                            "cityId": this.CityId,
                            "countyId": this.CountyId,
                            "remark": this.remark
                           }
     this.$axiosPost('/api/User/SaveCompanyInfoFromForm',this.$qs.stringify(datas)).then(res=>{
              console.log(res)
              if(res.Tag==1){
    //  this.$message.success( res.Message);
            if(this.type==1){
              this.$emit('success')
            }else{
              this.editFlag=true
            }
              }else{
        this.$message.error( res.Message);

                this.saveFlag=false
              }
     })
  
   },
    // 切换token
    async changeToken(val) {
      
    await  this.$axiosGet("/api/User/ChangeLoginFromForm", { customerId: val }).then(
        (res) => {
          if (res.Tag == 1) {
            localStorage.setItem("token", res.Data);
           
            this.$axiosMsg().then(res=>{
              
                 this.CompanyName = this.$store.getters.GETCompanyName
                this.CompanyCode = this.$store.getters.GETCompanyCode
                this.ElectricalProperty = this.$store.getters.GETElectricalProperty
                this.BigIndustryType = this.$store.getters.GETBigIndustryType
                this.SmallIndustryType = this.$store.getters.GETSmallIndustryType
                this.ProvinceId = this.$store.getters.GETProvinceId
                this.CityId = this.$store.getters.GETCityId
                this.CountyId = this.$store.getters.GETCountyId
                this.remark = this.$store.getters.GETremark
    
            })
         
     
             
          }
        }
      );
    },
  

      },



}
</script>

<style lang="scss" scoped>
.container_info{
    // width: 1656px;
    // width: 1656px;
    width: 100%;

//  height:calc(100vh - 110px);
    align-items: stretch;

 background: #fff;

}
.container_assembly{
        align-items: stretch;

 background: #fff;
}
.content_info{
     padding: 30px;
.title{
    font-size: 18px;
    width: 100%;
    height: 30px;
   position: relative;
  padding-left: 20px;
font-family: PingFang SC-Bold, PingFang SC;
font-weight: bold;
color: #2E353E;
padding-bottom: 16px;
border-bottom: 1px solid #DDE2E6;
display: flex;
align-items: center;
position: relative;
   &:before {
    content: "";
    width: 6px;
    height: 20px;
    background-color: #3e96fc;
    border-radius: 2px;
    display: block;
    position: absolute;
    left: 5px;
    top: 6px;
  }
}
.edit{
    width: 100%;
    margin: 30px 0;
    height: 40px;
    
}
.form{
    display: flex;
    justify-content: space-between;
    align-items: center;
 flex-wrap:wrap;
.form_tip{
font-size: 16px;
font-family: PingFang SC-Regular, PingFang SC;
font-weight: 400;
color: #808992;
}
.form_input{
    margin-top: 8px ;
    margin-bottom: 24px ;
font-size: 16px;
font-family: PingFang SC-Regular, PingFang SC;
font-weight: 400;
color: #475261;
 .elinput{
// width: 588px;
width: 100%;

}
el-button:nth-child(1){
    margin-right: 40px;
}

}
}
}

</style>